/* -------------------------------------------
 mouse starker
------------------------------------------- */

const stalker = document.createElement('div')
stalker.id = 'stalker'
document.body.appendChild(stalker)

const stalker2 = document.getElementById('stalker')

document.addEventListener('mousemove', function (e) {
  stalker2.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)'
})
