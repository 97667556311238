import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    flug: 0,
    bgmState: false,
    bgm: new Audio(require('@/assets/bgm.mp3'))
  },
  getters: {
    flug: state => state.flug,
    bgmState: state => state.bgmState
  },
  mutations: {
    flugup (state) {
      state.flug++
    },
    bgmchange (state) {
      state.bgmState = !state.bgmState
    }
  },
  actions: {
    flugup (context) {
      context.commit('flugup')
    },
    bgmchange (context) {
      context.commit('bgmchange')
    }
  }
})

export default store
