<template>
    <footer id="copyright" role="contentinfo">
        &copy; {{ copyright }} Kumano-Kotai-jinja
    </footer>
</template>

<script>
export default {
  data () {
    return {
      copyright: ''
    }
  },

  mounted () {
    const myDate = new Date()
    this.copyright = myDate.getFullYear()
  }
}
</script>

<style lang="scss" scoped>
  footer {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
</style>
