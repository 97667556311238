import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store'
import VueCookie from 'vue-cookie'
import VueGtm from 'vue-gtm'

require('@/assets/sass/main.scss')
require('@/assets/js/common.js')

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueCookie)

Vue.use(VueGtm, {
  id: 'GTM-PXF75RQ'
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
