<template>
    <div>
        <input type="checkbox" id="menu-btn-check" v-model="checked">
        <label for="menu-btn-check" class="menu-btn"></label>
        <nav id="globalNav" role="navigation">
            <ul class="drawer-menu" @click="closeMenu" :class="{ active: isActive}">
                <li class="nav_logo"><router-link to="/"><img src="@/assets/img/logo_s.svg" alt="軽井沢碓氷峠 信濃国特別神社 熊野皇大神社"></router-link></li>
                <li class="nav_about">
                    <router-link to="/aboutus">
                         <img src="@/assets/img/nav_about.svg" alt="特別神社">
                     </router-link>
                </li>
                <li class="nav_goshinboku"><router-link to="/goshinboku"><img src="@/assets/img/nav_goshinboku.svg" alt="しなの木"></router-link></li>
                <li class="nav_history">
                    <router-link :to="{ name: 'Home', hash: '#history' }">
                        <img src="@/assets/img/nav_history.svg" alt="由緒">
                    </router-link>
                </li>
                <li class="nav_gokito"><router-link to="/gokito"><img src="@/assets/img/nav_gokito.svg" alt="ご祈祷"></router-link></li>
                <li class="nav_gaisai"><router-link to="/gaisai"><img src="@/assets/img/nav_gaisai.svg" alt="外祭"></router-link></li>
                <li class="nav_goshuin"><router-link to="/goshuin"><img src="@/assets/img/nav_goshuin.svg" alt="御朱印"></router-link></li>
                <!-- <li class="nav_lgetUserimited"><router-link to="/limited"><img src="@/assets/img/nav_limited.svg" alt="限定品"></router-link></li> -->
                <li class="nav_access"><router-link to="/jinjamap"><img src="@/assets/img/nav_access.svg" alt="神社 MAP"></router-link></li>
                <li class="nav_ujikomap"><router-link to="/ujikomap"><img src="@/assets/img/nav_ujikomap.svg" alt="氏子 MAP"></router-link></li>
                <li class="nav_ujikomap"><a href="https://shop.kumanokoutai.com/" target="_blank"><img src="@/assets/img/nav_shop.svg" alt="ネットショップ"></a></li>
            </ul>
            <!-- <div class="drwer-guji">
                <img src="@/assets/img/guji.svg" alt="">
                <img src="@/assets/img/guji_name.svg" alt="宮司 水澤貴文">
                <div class="guji-point">
                    <div class="guji_heart"><a href="#"><img src="@/assets/img/icn_heart.svg" alt=""></a></div>
                    <dl>
                        <dt>LEVEL</dt><dd>999</dd>
                        <dt>EX.</dt><dd>10000</dd>
                    </dl>
                </div>
            </div> -->
            <div class="box">
              <label>
               <input type="checkbox" class="nes-checkbox is-dark" @change="soundOn" v-model="checkedSound" />
               <span>sound on</span>
              </label>
            </div>

            <AvatorBox :class="{ active: isHide}"></AvatorBox>
            <button type="button" class="nes-btn is-success" @click="open">宮司のプロフィール</button>
        </nav>
    </div>
</template>

<script>
import AvatorBox from '@/components/AvatorBox.vue'

export default {
  components: {
    AvatorBox
  },

  data () {
    return {
      checked: false,
      isActive: false,
      isHide: true,
      /* bgm: new Audio(require('@/assets/bgm.mp3')), */
      bgm: this.$store.state.bgm,
      checkedSound: this.$store.state.bgmState
    }
  },

  mounted () {
    /* console.log('mounted is ' + this.checkedSound) */
    /* console.log('mounted paused is ' + this.bgm.paused) */
    /* console.log(this.bgm) */
    /* console.log(this.$store.state.bgm) */
  },

  methods: {
    closeMenu () {
      this.checked = !this.checked
      /* console.log(this.checked) */
    },

    open () {
      this.isActive = !this.isActive
      this.isHide = !this.isHide
    },

    soundOn () {
      this.checkedSound ? this.bgm.play() : this.bgm.pause()
      this.$store.dispatch('bgmchange')
    }
  }
}
</script>

<style lang="scss" scoped>
    nav {
        transition: 1s;
        transform: translateX(0);
    }
    .active {
        transform: translateX(-200%);
    }
    .hide {
        transform: translateX(-200%);
    }
    button, label {
        font-family: 'NuKinakoMochi-BarVtE-1'
    }
    button {
      width: 94%;
    }
</style>
