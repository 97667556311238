<template>
  <div class="home">
    <!-- openning -->
    <div class="flex">
      <div class="open-r open" ref="box1" ></div>
      <div class="open-l open" ref="box2" ></div>
    </div>

    <Head :reffaral="ref" :locale="locale"></Head>
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
          <h2><img src="@/assets/img/head_about.svg" alt="県境の神社"></h2>
          <div class="box">
              <img src="@/assets/img/keidai.jpg" alt="神社の中央が県境となり、左側が長野県 熊野皇大神社、右側が群馬県 熊野神社 です。" class="fade">
          </div>
          <TxtAnime>日本三大熊野のひとつ信濃國特別特別神社に指定された、全国的にも珍しい県境に位置する神社として知られています。古来からの豊かな自然を今に伝え、碓氷峠より軽井沢を見守り続けています。</TxtAnime>
          <aside class="btn"><router-link to="/aboutus"><img src="@/assets/img/btn_detail.svg" alt="詳しく見る"></router-link></aside>
        </section>

        <section>
          <Modal></Modal>
        </section>

        <section id="goshinboku">
          <h2><img src="@/assets/img/head_goshinboku.svg" alt="しなの木"></h2>
          <div class="box">
            <img src="@/assets/img/goshinboku.gif" alt="御神木のしなの木" class="fade">
          </div>
          <TxtAnime>樹齢1000年以上のご神木です。このご神木が信濃（現在の長野県）の国名の語源となったと云われています。（諸説あり）「結ぶ・くくる・しばる」という意味で、古来より開運・縁結びのご神木として信仰されてきました。今も軽井沢のパワースポットのひとつとして参拝者の信仰を集めています。しなの木の葉はハートの形をしており、7月頃に白く小さい花をたくさん咲かせます。</TxtAnime>
          <aside class="btn"><router-link to="/goshinboku"><img src="@/assets/img/btn_detail.svg" alt="詳しく見る"></router-link></aside>
        </section>

        <section id="history" class="section">
          <h2><img src="@/assets/img/head_history.svg" alt="由緒"></h2>
          <div class="box">
            <!-- <div class="sideWrap" ref="sidebox"> -->
            <div>
              <img src="@/assets/img/main_yuisho01.jpg" alt="素戔嗚尊">
              <!-- <img src="@/assets/img/susanoo.jpg" alt="素戔嗚尊">
              <img src="@/assets/img/main01.png" alt="素戔嗚尊"> -->
            </div>
          </div>
          <TxtAnime>当神社に伝わる由緒記や古事記、日本書紀によると創建は景行天皇40年（西暦110年）10月。約1900年前にさかのぼります。日本武尊（ヤマトタケルノミコト）が碓氷峠を通りかかると先が見えぬほどの深い霧に覆われました。その時、梛（ナギ）の葉を落としながら先導する一羽の八咫烏（ヤタガラス）に遭遇。後をついて行くと無事に登頂できたと言い伝えられています。<br>その昔、先祖の神武天皇を熊野国（和歌山）から大和国（奈良）へと導いたのも八咫烏だったことから、日本武尊は今回の登頂も熊野神霊の御加護によるものと心より感謝し、熊野三社を祀ったと伝えられています。また、碓氷峠から旅立つ際は光り輝く蛇が道案内をし、無事に下山を見届けたあと、蛇は龍になり、天へ帰っていったという伝説も残っています。<br>熊野皇大神社は多くの武将にも崇敬されていました。鎌倉幕府を開いた源頼朝は、浅間に狩りに訪れた際、長雨に悩まされ当神社で晴天祈願をしました。感謝をした源頼朝は狩りで使う上矢（鏑矢）を奉納したと伝えられています。<br>また新田義貞は崇敬の念が篤く、東は上州新堀村鳥居坂（現 松井田町）から西は信州佐久郡鳥井原村（現 町内鳥井原地区）まで神領として寄進し、両端に大鳥居を立てたほどでした。真田幸村も初陣で碓氷峠を通りかかった折、 当神社で必勝を祈願したと伝えられています。</TxtAnime>
        </section>

        <section id="gokito" class="section">
          <h2><img src="@/assets/img/head_gokitos.svg" alt="ご祈祷・外祭"></h2>
          <ul class="column2-1">
            <li class="fade"><router-link to="/gokito"><figure><img src="@/assets/img/home_gokito.jpg" alt=""></figure></router-link><div class="box"><p>ご祈祷</p></div></li>
            <li class="fade"><router-link to="/gaisai"><figure><img src="@/assets/img/home_gaisai.jpg" alt=""></figure></router-link><div class="box"><p>外祭</p></div></li>
          </ul>
        </section>

        <section id="goshuin" class="section">
          <h2><img src="@/assets/img/head_goshuin_ec.svg" alt="御朱印など"></h2>
          <ul class="column2-1">
            <li class="fade"><router-link to="/goshuin"><figure><img src="@/assets/img/home_goshuin.jpg" alt=""></figure></router-link><div class="box"><p>ご朱印</p></div></li>
            <li class="fade"><a href="https://shop.kumanokoutai.com/" target="_blank"><figure><img src="@/assets/img/home_shop.jpg" alt=""></figure></a><div class="box"><p>ネットショップ</p></div></li>
          </ul>
        </section>

        <section id="jinjamap" class="section">
          <h2><img src="@/assets/img/head_map.svg" alt="神社マップ"></h2>
          <ul class="column2-1">
            <li class="fade"><router-link to="/jinjamap"><figure><img src="@/assets/img/home_jinjamap.jpg" alt=""></figure></router-link><div class="box"><p>神社マップ</p></div></li>
            <li class="fade"><router-link to="/ujikomap"><figure><img src="@/assets/img/home_ujikomap.jpg" alt=""></figure></router-link><div class="box"><p>軽井沢氏子神社マップ</p></div></li>
          </ul>
        </section>

        <section id="access" class="section">
          <h2><img src="@/assets/img/head_access.svg" alt="アクセス"></h2>
          <div class="box">
            <ul class="square">
              <li>車で上信越道「碓氷軽井沢IC」より約30分</li>
              <li>旧軽井沢より車で約5分 徒歩約45分</li>
              <li>軽井沢駅より車で約10分</li>
              <li>旧軽井沢観光会館前より路線バスがあります。</li>
              <li>大型バスの乗り入れはお問い合わせください。</li>
            </ul>
            <img src="@/assets/img/map.gif" alt="アクセスマップ" class="pc fade">
            <aside class="btn pc"><a target="_blank" href="https://goo.gl/maps/nYAX4sAkRNZ2wMn36"><img src="@/assets/img/btn_gm.svg" alt="Google Map で見る"></a></aside>
          </div>
          <aside class="btn sp"><a target="_blank" href="https://goo.gl/maps/nYAX4sAkRNZ2wMn36"><img src="@/assets/img/btn_gm.svg" alt="Google Map で見る"></a></aside>
        </section>

        <section id="footer" class="footer">
          <picture>
            <source srcset="@/assets/img/stones_pc.svg" media="(min-width: 1000px)">
            <img src="@/assets/img/stones_sp.svg">
          </picture>
          <div class="box">
            <div>
              <address>熊野皇大神社<br>〒389-0101 長野県北佐久郡軽井沢町峠町１</address>
              <p>最新情報はFacebookやInstagramで配信中</p>
            </div>
            <ul>
              <li><a target="_blank" rel="nofllow" href="https://www.instagram.com/kumanokoutai/?hl=ja"><i class="nes-icon instagram is-medium"></i></a></li>
              <li><a target="_blank" rel="nofllow" href="https://www.facebook.com/kumanokoutaijinja/"><i class="nes-icon facebook is-medium"></i></a></li>
            </ul>
          </div>
        </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'
import Modal from '@/components/Modal.vue'
import TxtAnime from '@/components/TxtAnime.vue'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  name: 'Home',
  components: {
    Head,
    Foot,
    Nav,
    TxtAnime,
    Modal
  },

  data () {
    return {
      ref: null,
      locale: null
    }
  },

  mounted () {
    this.ref = this.$router.referrer.fullPath
    this.locale = window.location.host
    /* const bh = document.getElementsByTagName('body') */
    /* console.log(bh) */
    /* console.log(this.ref) */
    /* console.log(this.locale) */

    gsap.registerPlugin(ScrollTrigger)
    /* const el = this.$refs.sidebox */
    /* console.log(this.$refs.sidebox) */
    if (this.ref === '/') {
      /* open */
      gsap.to('.open-l', {
        x: -1000,
        delay: 1.5,
        duration: 0.5,
        display: 'none',
        ease: 'power4.in'
      })

      gsap.to('.open-r', {
        x: 1000,
        delay: 1.5,
        duration: 0.5,
        display: 'none',
        ease: 'power4.in'
      })
    } else {
      gsap.to('.open-l', {
        opacity: 0,
        display: 'none'
      })
      gsap.to('.open-r', {
        opacity: 0,
        display: 'none'
      })
    }

    /* gsap.to(el, {
      xPercent: -185,
      scrollTrigger: {
        trigger: '#history',
        start: 'top top',
        end: `+=${el.clientWidth}`,
        scrub: true,
        pin: true
      }
    }) */

    /* fade in */
    gsap.set('.fade', {
      opacity: 0,
      y: -10
    })
    ScrollTrigger.batch('.fade', {
      onEnter: batch => gsap.to(batch, {
        opacity: 1,
        duration: 1,
        delay: 1,
        ease: 'power2.out',
        stagger: {
          amount: 0.5
        },
        y: 0
      })
    })
  }
}
</script>

<style lang="scss" scoped>
  #history {
    .box {
      overflow: hidden;
      .sideWrap {
        @include flex {
          flex-wrap: nowrap;
        }
        img {
          margin-right: 1rem;
        }
      }
    }
  }

  .open {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 9999;
  }
  .open-r {
    width: 50%;
    height: 100vh;
    right: 0;
    background: $openning url('~@/assets/kamon.png') left -32% center /39% no-repeat;
    overflow: hidden;
  }
  .open-l {
    width: 50%;
    height: 100vh;
    background: $openning url('~@/assets/kamon.png') right -32% center /39% no-repeat;
    overflow: hidden;
    left: 0;
  }
  .btn {
    width: 45%;
    margin: 0 auto;
  }
  #access {
    .btn {
      margin-top: 1.5rem;
    }
  }
  ul, address, p {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  .box p {
    text-indent: 0;
  }
  figure {
    position: relative;
    background: url('~@/assets/img/goshuin_dammy.png') no-repeat center /contain;
    padding: 36%;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 73%;
    }
  }
</style>
