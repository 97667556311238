import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue')
  },
  {
    path: '/limited',
    name: 'Limited',
    component: () => import('../views/Limited.vue')
  },
  {
    path: '/goshinboku',
    name: 'Goshinboku',
    component: () => import('../views/Goshinboku.vue')
  },
  {
    path: '/gokito',
    name: 'Gokito',
    component: () => import('../views/Gokito.vue')
  },
  {
    path: '/gaisai',
    name: 'Gokito',
    component: () => import('../views/Gaisai.vue')
  },
  {
    path: '/goshuin',
    name: 'Goshuin',
    component: () => import('../views/Goshuin.vue')
  },
  {
    path: '/jinjamap',
    name: 'Jinjamap',
    component: () => import('../views/Jinjamap.vue')
  },
  {
    path: '/ujikomap',
    name: 'Ujikomap',
    component: () => import('../views/Ujikomap.vue')
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: () => import('../views/Aboutus.vue')
  },
  {
    path: '/*',
    name: 'notfound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  },
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  router.referrer = from
  next()
})

export default router
